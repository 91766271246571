@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');

html, body {
  height: 100% !important;
}

.guide {
  border: solid 1px yellow;
}


body {
  margin: 0;
  color: #484848;
  background: #F0F2F2;
  font-family: 'Source Sans Pro', sans-serif;
}

h1 {
  font-weight: 700;
  font-size: 5em !important;
}

h2 {
  font-weight: 700;
  font-size: 2em !important;
  color: #020202
}

h5 {
  font-weight: 700;
  color: #092640,
}

.blue {
  color: #092640;
}

.navbar-transparent {
  background: none !important;
}

.intro {
  background: #FFF url('./assets/picnic.jpg') no-repeat center;
  background-size: cover;
  height: 700px;
  z-index: -1
}

.intro-transfers {
  background: #FFF url('./assets/bmw.jpg') no-repeat bottom;
  background-size: cover;
  height: 700px;
  z-index: -1
}

.intro-tours {
  background: #FFF url('./assets/giraffe.jpg') no-repeat center;
  background-size: cover;
  height: 700px;
  z-index: -1
}

.intro-staff {
  background: #FFF url('./assets/owners.jpg') no-repeat center;
  background-size: cover;
  height: 700px;
  z-index: -1
}

.logo {
  width: 150px;
  padding: 20px;
  border-radius: 150px;
}

.services {
  margin-top: -100px;
}


@media only screen and (max-width: 767px) {
  h1 {
    font-weight: 700;
    font-size: 2.5em !important;
  }
  .logo {
    width: 100px;
    padding: 10px;
    border-radius: 50px;
  }
  .services {
    margin-top: -30px;
    background-color: #092640;
  }
  .intro {
    background-size: cover;
  }
  .intro-transfers {
    // background: #FFF url('./assets/bmw.jpg') no-repeat center;
    // background-size: cover;
    height: 500px;
  }
  .intro-tours {
    background: #FFF url('./assets/giraffe.jpg') no-repeat;
    background-size: cover;
    height: 500px;
  }
  .intro-staff {
    background: #FFF url('./assets/owners.jpg') no-repeat center;
    background-size: cover;
    height: 500px;
  }
}

footer {
  background: #092640;
}

input[type="text"],
input[type="tel"],
input[type="date"],
.DateInput_input,
.form-control[readonly] {
  -webkit-transition: border-color .2s;
  -moz-transition: border-color .2s;
  transition: border-color .2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  padding: 6px 10px;
  height: 45px !important;
  border: 1px solid #f2f2f2 !important;
  border-radius: .10rem !important;
  background-color: #fff !important;
  vertical-align: middle;
  line-height: normal;
  max-width: 100% !important;
  font-size: 14px;
  color: #818182;
  width: 100%;
}

.btn {
  border-radius: 0.15rem !important;
}

.btn-success {
  color: #fff;
  background-color: #D93B3B;
  border-color: #D93B3B;
}
.btn-success:hover {
  color: #fff;
  background-color: #5A708C;
  border-color: #5A708C;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #5A708C;
  border-color: #5A708C;
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}


.img-thumbnail-bmw {
  background: url('./assets/bmw-side.jpg') no-repeat right center;
  background-size: cover;
  height: 200px;
  border-radius: 5px;
}

.img-thumbnail-tours {
  background: url('./assets/giraffe.jpg') no-repeat left center;
  background-size: cover;
  border-radius: 5px;
  height: 200px; 
}

.img-thumbnail-staff {
  background: url('./assets/cashier.jpg') no-repeat right center;
  background-size: cover;
  border-radius: 5px;
  height: 200px; 
}